export default class Facebook {
  constructor(clientId) {
    this.loginProviderObj = {
      id: clientId,
      name: 'facebook',
      url: 'https://connect.facebook.net/en_US/sdk.js'
    };
  }

  static drawUser(response) {
    return {
      id: response.id,
      name: response.name,
      email: response.email,
      image: `https://graph.facebook.com/${response.id}/picture?type=normal`,
      token: response.token
    };
  }

  loadScript(obj, onload) {
    if (document.getElementById(obj.name)) {
      return;
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = obj.url;
    script.onload = onload;
    document.head.appendChild(script);
  }

  async initialize() {
    return new Promise((resolve, reject) => {
      this.loadScript(this.loginProviderObj, () => {
        window.FB.init({
          appId: this.loginProviderObj.id,
          autoLogAppEvents: true,
          cookie: true,
          xfbml: true,
          version: 'v21.0'
        });

        window.FB.AppEvents.logPageView();

        // window.FB.getLoginStatus((response) => {
        //   if (response.status === 'connected') {
        //     const accessToken = response.authResponse.accessToken;
        //     window.FB.api(
        //       '/me?fields=name,email,picture',
        //       { access_token: accessToken }, // Explicit token usage
        //       (res) => {
        //         if (res.error) {
        //           reject(res.error);
        //         } else {
        //           resolve(
        //             Facebook.drawUser(
        //               Object.assign({}, { token: accessToken }, res)
        //             )
        //           );
        //         }
        //       }
        //     );
        //   } else {
        //     resolve(null); // Not logged in
        //   }
        // });
      });
    });
  }

  async signIn() {
    return new Promise((resolve, reject) => {
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const accessToken = response.authResponse.accessToken; // Retrieve token
            window.FB.api(
              '/me?fields=name,email,picture',
              { access_token: accessToken }, // Explicit token usage
              (res) => {
                if (res.error) {
                  reject(res.error);
                } else {
                  resolve(
                    Facebook.drawUser(
                      Object.assign({}, { token: accessToken }, res)
                    )
                  );
                }
              }
            );
          } else {
            reject('User cancelled login or did not fully authorize.');
          }
        },
        { scope: 'email,public_profile' }
      );
    });
  }

  async signOut() {
    return new Promise((resolve) => {
      window.FB.logout((response) => {
        resolve(response);
      });
    });
  }
}
