<template>
  <auth-layout>
    <div class="max-w-xl w-full space-y-4 rounded bg-white dark:bg-gray-800 dark:text-gray-400 shadow p-4">
      <h1 class="text-xl font-bold mb-4 text-center">{{ translate('login.title') }}</h1>
      <form @submit.prevent="submit">
        <div class="mb-2">
          <div class="flex items-center justify-between mb-1">
            <label>{{ translate('login.username') }}</label>
            <div class="text-red-500 text-sm">{{ showError(errorBag, 'User') }}</div>
          </div>
          <input v-model="form.User" autofocus class="form-input w-full rounded" type="text" />
        </div>
        <div class="mb-2">
          <div class="flex items-center justify-between mb-1">
            <label>{{ translate('login.password') }}</label>
            <div class="text-red-500 text-sm">{{ showError(errorBag, 'Password') }}</div>
          </div>
          <input v-model="form.Password" class="form-input w-full rounded" type="password" />
        </div>
        <div class="mb-2">
          <div class="flex items-center justify-between mb-1">
            <label>{{ translate('login.twofa') }}</label>
            <div class="text-red-500 text-sm">{{ showError(errorBag, 'totp') }}</div>
          </div>
          <input v-model="form.totp" class="form-input w-full" type="text" @keyup="onlyNumeric($event)" />
        </div>
        <div>
          <vue-recaptcha ref="vueRecaptcha" :sitekey="reCaptchaAPIKey" size="normal" @expire="recaptchaExpired"
            :theme="mode" @fail="recaptchaFailed" @verify="recaptchaVerified">
          </vue-recaptcha>
          <div class="text-red-500 text-sm">{{ showError(errorBag, 'reCaptcha') }}</div>
        </div>
        <div class="mt-4">
          <j-button :disabled="isProcessing" size="w-full">
            <div v-if="isProcessing" class="mr-3">
              <icon :name="'spinner'" classes="w-4 h-4 text-white"></icon>
            </div>
            <span>{{ translate('login.button') }}</span>
          </j-button>
        </div>
      </form>
      <div class="text-center">
        <div class="flex items-center">
          <!--<p class="mr-3">{{ translate('login.forgot') }}   login.recover</p>-->
          <router-link class="link" to="/recover-password">{{ translate('login.forgot') }}</router-link>
        </div>
      </div>
      <div class="text-center font-bold my-4">
        <span>Or...</span>
      </div>
      <j-button size="w-full" buttonStyle="outline" @click="authenticate('facebook')">
        <img class="w-5 h-5 mr-3" src="@/assets/images/facebook.svg" alt="facebook" />
        Sign In With Facebook
      </j-button>
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from '@/components/layouts/AuthLayout';
import vueRecaptcha from 'vue3-recaptcha2';
import JButton from '@/templates/Button';
import Icon from '@/components/Icon';
import { useCookies } from 'vue3-cookies';
import { FETCH_ACCOUNT_INFO, FETCH_POS_DATA, PERSIST_AUTH, SET_LOGIN_TYPE } from '@/store/keys';
import { computed, inject, onBeforeMount, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import validator from '@/validator';
import { useRouter } from 'vue-router';
import compositionUtils from '@/compositionUtils';
import Facebook from '../libs/Facebook';

export default {
  name: 'Login',
  components: {
    AuthLayout,
    vueRecaptcha,
    JButton,
    Icon
  },
  setup() {
    const { cookies } = useCookies();
    const vueRecaptcha = ref(null);
    // Provides
    const http = inject('http');
    const toast = inject('toast');
    const translate = inject('translate');

    const { saveNotification } = compositionUtils();

    // Store
    const store = useStore();
    // Router
    const router = useRouter();

    const {
      validate,
      showError
    } = validator();
    // State
    const state = reactive({
      reCaptchaAPIKey: process.env.VUE_APP_GOOGLE_reCAPTCHA_SITE_KEY,
      form: {
        User: '',
        Password: '',
        totp: '000000',
        reCaptcha: '',
        output: 'json'
      },
      errorBag: {},
      rules: {
        User: ['required'],
        Password: ['required'],
        totp: ['nullable|len:6'],
        reCaptcha: ['required']
      },
      messages: {
        User: {
          required: 'Username is required',
          regex: 'Username should only contain alpha numeric, max 60 characters'
        },
        Password: {
          required: 'Password is required'
        },
        totp: {
          required: 'Google 2FA code is required',
          integer: 'Only numeric value is allowed'
        },
        reCaptcha: {
          required: 'Verify you are not a robot'
        }
      },
      isSocial: false,
      facebook: null,
      isProcessing: false
    });

    // Computed
    const mode = computed(() => store.state.mode);
    const isLoggedIn = computed(() => store.state.isLoggedIn);

    // Watch
    watch(() => ({ ...state.form }),
      (newVal, oldVal) => {
        if (Object.keys(state.errorBag).length > 0) {
          state.errorBag = validate(state.form, state.rules, state.messages);
        }
      });

    // Methods
    const recaptchaVerified = (response) => {
      state.form.reCaptcha = response;
    };
    const recaptchaExpired = () => {
      vueRecaptcha.value.reset();
      state.form.reCaptcha = '';
    };
    const recaptchaFailed = () => {
    };
    const createHash = (username) => {
      let hash = 0;
      for (let i = 0; i < username.length; i++) {
        const character = username.charCodeAt(i);
        hash = ((hash << 5) - hash) + character;
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    };

    const onlyNumeric = (evt) => {
      if (isNaN(Number(evt.key)) || state.form.totp.length > 6) {
        state.form.totp = state.form.totp.slice(0, state.form.totp.length - 1);
      }
    };

    const authenticate = (provider) => {
      if (provider === 'facebook') {
        state.facebook.signIn().then(userData => {
          if (userData) {
            state.isSocial = true;
            if (typeof userData.email !== 'undefined') {
              signup(userData);
            } else {
              toast.error('Please verify your email on facebook or try to sign up manually');
            }
          }
        }).catch(error => {
          console.log(error);
        });
      }
    };
    const submit = () => {
      state.errorBag = validate(state.form, state.rules, state.messages);
      if (Object.keys(state.errorBag).length === 0) {
        state.isProcessing = true;
        const payload = state.form;
        payload['g-recaptcha-response'] = state.form.reCaptcha;
        if (payload.totp === '') {
          payload.totp = '000000';
        }
        delete payload.reCaptcha;
        login(payload);
      }
    };

    const apiBaseUrl = process.env.VUE_APP_API_URL;
    const signup = (userData) => {
      const url = new URL(window.location.href);
      let r = url.searchParams.get('r');
      r = r === null ? 0 : r;
      let exists = '';
      let params = {
        Call: 'CreateAccount',
        MerchantID: 1,
        APIKey: '_',
        selectedaltcoins: 1,
        shoptitle: userData.name.replace(/ /g, '').toLowerCase(),
        email: userData.email,
        ownernickname: userData.email.replace(/[^a-z0-9]/gi, ''),
        ownerpassword: userData.id,
        locale: 'EN',
        Sticky: 1,
        r,
        output: 'json'
      };
      http.get(`${apiBaseUrl}/v2REAPI`, {
        params
      }).then(response => {
        if ((typeof response.data === 'string' || response.data instanceof String) && response.data.startsWith('The Username ' + params.ownernickname + ' exists')) {
          exists = response.data;
          const username = params.ownernickname;
          const apiToken = createHash(username);
          params = {
            User: username,
            Password: userData.id,
            totp: '000000',
            api: apiToken,
            output: 'json'
          };
          // Log user in
          http.get(`${apiBaseUrl}/Login`, {
            params
          }).then(response => {
            if (response.data === 'Session already exists') {
              toast.error(response.data);
            } else {
              // Commit user to store
              const user = response.data;
              user.Username = username;
              persistUser(user, userData.id);
            }
          }).catch(error => {
            console.log(error);
            if (exists !== '') {
              saveNotification(exists);
              toast.error(exists);
            } else {
              toast.error(error.response.data);
            }
          });
        } else {
          // Commit user to store
          const user = response.data;
          user.Username = params.ownernickname;
          persistUser(user, params.Password);
        }
      }).catch(error => {
        if (state.isSocial) {
          state.facebook.signOut().then(() => {
          });
        }
        console.log(error);
      });
    };
    const persistUser = (user, password) => {
      delete user.Status;
      // Set cookie
      cookies.set('JSESSIONID', user.SessionID, {
        expires: '1Y',
        domain: '.cointopay.com'
      });

      store.dispatch(PERSIST_AUTH, user).then(() => {
        store.dispatch(FETCH_POS_DATA, {
          User: user.Username,
          Password: password
        });
        // Get account info
        store.dispatch(FETCH_ACCOUNT_INFO).then(response => {
          store.commit(SET_LOGIN_TYPE, true);
          // Redirect
          router.push({ name: 'wallet' });
        }).catch(error => {
          console.log(error.response);
        });
      });
    };
    const login = (payload, provider = null, userData = null) => {
      http.get(`${process.env.VUE_APP_API_URL}/Login`, {
        params: payload
      }).then(response => {
        if (response.data === 'Session already exists') {
          toast.error(response.data);
          state.isProcessing = false;
        } else {
          // Commit user to store
          const user = response.data;
          delete user.Status;
          user.Username = payload.User;
          // Set cookie
          cookies.set('JSESSIONID', user.SessionID);
          store.dispatch(PERSIST_AUTH, user).then(() => {
            // Fetch POS data
            store.dispatch(FETCH_POS_DATA, payload);
            if (provider) {
              store.commit(SET_LOGIN_TYPE, !!provider);
            }
            // Fetch account info
            store.dispatch(FETCH_ACCOUNT_INFO).then(response => {
              state.isProcessing = false;
              const route = store.state.currentRoute;
              // Redirect
              if (route.startsWith('/account')) {
                router.push(route);
              } else {
                router.push('/wallet');
                toast.success('Welcome to Cointopay');
              }
            }).catch(error => {
              state.isProcessing = false;
              console.log(error.response);
            });
          });
        }
      }).catch(error => {
        console.log(error);
        state.isProcessing = false;
        toast.error(error.response ? error.response.data : error.data);
        if (state.isSocial) {
          state.facebook.signOut().then(() => {
          });
        } else {
          vueRecaptcha.value.reset();
        }
      });
    };

    onBeforeMount(() => {
      state.facebook = new Facebook(process.env.VUE_APP_FACEBOOK_ID);
      if (state.facebook) {
        state.facebook.initialize().then((response) => {
        }).catch((error) => {
          console.log(error);
        });
      }
      if (isLoggedIn.value) {
        const route = store.state.currentRoute;
        router.push(route);
      }
    });

    return {
      translate,
      ...toRefs(state),
      cookies,
      vueRecaptcha,
      mode,
      showError,
      recaptchaVerified,
      recaptchaExpired,
      recaptchaFailed,
      onlyNumeric,
      authenticate,
      submit
    };
  }
};
</script>

<style scoped lang="scss">
.rc-anchor-dark {
  @apply bg-gray-800;
}

.link {
  @apply text-blue-500 hover:underline;
}
</style>
